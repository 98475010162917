import { FC, useContext } from "react";
import ConnectButton from "../Components/ConnectButton/ConnectButton";
import { Web3Context } from "../Components/Web3Context/Web3Context";

interface HeaderProps {

}

const Header: FC<HeaderProps> = (props) => {
    const { connectWeb3, disconnectWeb3, web3Connected, walletAddress } = useContext(Web3Context);

    return (
        <div className="mb-8">
            <div className="w-48 float-right mr-5">
                <ConnectButton
                    className=""
                    connectWeb3={connectWeb3}
                    disconnectWeb3={disconnectWeb3}
                    web3Connected={web3Connected}
                    walletAddress={walletAddress}
                />
            </div>
        </div>
    );
}

export default Header;