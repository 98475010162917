import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import { groupBy, log } from '../../Common/HelperFunctions';

import { BinanceTickerApiResponse, SmartContractAddressMap, TokenInformationResponse } from '../../Common/Models';
import { getFarmApyStats, getLendingPool, getUserPositions } from './SubGraph/SubGraph';
import { LendingGraphResponse } from '../../Common/Models/LendingGraph';
import { UserPositionsResponse } from '../../Common/Models/UserPositions';
import { BeefyFarmApyStatsResponse } from "../../Common/Models/BeefyFarmApyStats";
import { LpStats, LpStat } from "../../Common/Models/LPStat";

interface Web3ProviderProps {
}

export type Web3ProviderContextType = {
    Web3Connection: Web3 | null | undefined,
    connectWeb3: (provider: string | undefined) => void;
    disconnectWeb3: () => void;
    web3Connected: boolean;
    currentChainId: number;
    walletAddress: string;
    providerName: string;
    isAdmin: boolean;

    bnbBalance: number;
    bnbUsdtPrice: number;

    transak: {
        "ApiKey": string,
        "Environment": string
    },

    lendingGraph: LendingGraphResponse | null;
    userPositions: UserPositionsResponse | null;
    farmApyStats: BeefyFarmApyStatsResponse | null,
    // farmTvlStats: BeefyFarmTvlStatsResponse | null,
    lpStats: LpStats | null,

    fetchApprovalAmountForRouter: (spender: string, decimals: number) => Promise<number>;
    fetchApprovalAmountForSpender: (spender: string, contract: string, decimals: number) => Promise<number>;
    approveAmountForRouter: (spender: string, decimals: number) => void;
    approveAmountForSpender: (spender: string, contract: string, decimals: number) => void;

    approveAsset: (poolAsset: string) => void;
    fetchAssetApprovalAmount: (poolAsset: string) => Promise<number>;
    fetchAssetBalance: (poolToken: string) => Promise<number>;
    fetchPhubStakedBalance: (phubrewardpooladdress: string) => Promise<number>;
    fetchPhubEarnedRewards: (phubrewardpooladdress: string) => Promise<number>;
    fetchAssetExchangeRate: (poolToken: string) => Promise<number>;

    fetchPhubBoughtBack: () => Promise<number>;
    fetchPhubApr: (phubrewardpooladdress: string, price: number, totalStaked: number) => Promise<number>;
    fetchPhubTvl: (phubrewardpooladdress: string) => Promise<number>;


    stakeAsset: (poolToken: string, amount: number) => void;
    unstakeAsset: (poolToken: string, amount: number) => void;
    depositCollateral: (poolToken: string, amount: number) => void;
    withdrawCollateral: (poolToken: string, amount: number) => void;
    leveragePool: (poolToken: string, amountADesired: number, amountBDesired: number, amountAMin: number, amountBMin: number) => void;
    deleveragePool: (poolToken: string, redeemTokens: number, amountAMin: number, amountBMin: number) => void;
    zapIn: (toTokenAddress: string, amount: number, outputAmountMin: number) => void;
    zapInToken: (fromTokenAddress: string, toTokenAddress: string, amount: number, outputAmountMin: number) => void;
    getTokenPriceFromPancakeswapInDollars: (tokenAddress: string) => Promise<number>;

    fetchPhubPresaleData: () => object;
    buyWithBomb: (token: string, amount: number) => void;
    buyWithBbond: (token: string, amount: number) => void;
    buyWithBshare: (token: string, amount: number) => void;

    stakePhubrewardPool: (phubrewardpooladdress: string, amount: number) => void;
    unstakePhubrewardPool: (phubrewardpooladdress: string, amount: number) => void;
    exitPhubrewardPool: (phubrewardpooladdress: string) => void;
    getRewardPhubrewardPool: (phubrewardpooladdress: string) => void;

    getContract: (address: string, abi: any, skipCache: boolean) => any;
    getRpcContract: (address: string, abi: any, skipCache: boolean) => any;
}
const notImplementedFunc = () => console.warn('method not set up...');
export const Web3Context = React.createContext<Web3ProviderContextType>({
    Web3Connection: null,
    currentChainId: 56,
    connectWeb3: notImplementedFunc,
    disconnectWeb3: notImplementedFunc,
    web3Connected: false,
    walletAddress: "",
    providerName: "",

    transak: {
        ApiKey: "",
        Environment: ""
    },

    isAdmin: false,
    bnbBalance: 0,
    bnbUsdtPrice: 0,

    lendingGraph: null,
    userPositions: null,
    farmApyStats: null,
    // farmTvlStats: null,
    lpStats: null,

    fetchApprovalAmountForRouter: async (poolAsset) => {
        return 0;
    },
    fetchApprovalAmountForSpender: async (poolAsset, contract: string) => {
        return 0;
    },
    approveAmountForRouter: notImplementedFunc,
    approveAmountForSpender: notImplementedFunc,

    approveAsset: notImplementedFunc,
    fetchAssetApprovalAmount: async (poolAsset) => {
        return 0;
    },
    fetchAssetBalance: async (poolAsset) => {
        return 0;
    },
    fetchPhubStakedBalance: async (phubrewardpooladdress) => {
        return 0;
    },
    fetchAssetExchangeRate: async (poolAsset) => {
        return 0;
    },
    fetchPhubEarnedRewards: async (phubrewardpooladdress) => {
        return 0;
    },
    fetchPhubApr: async (phubrewardpooladdress: string, price: number, totalStaked: number) => {
        return 0;
    },
    fetchPhubTvl: async (phubrewardpooladdress) => {
        return 0;
    },


    stakeAsset: notImplementedFunc,
    unstakeAsset: notImplementedFunc,
    depositCollateral: notImplementedFunc,
    withdrawCollateral: notImplementedFunc,
    leveragePool: notImplementedFunc,
    deleveragePool: notImplementedFunc,
    zapIn: notImplementedFunc,
    zapInToken: notImplementedFunc,
    getTokenPriceFromPancakeswapInDollars: async (poolAsset) => {
        return 0;
    },

    fetchPhubPresaleData: async () => {
        return {
            totalTokens: 0,
            soldTokens: 0,
            availableTokens: 0,
            starTime: 0,
        };
    },

    fetchPhubBoughtBack: async () => {
        return 0;
    },
        
    buyWithBomb: notImplementedFunc,
    buyWithBbond: notImplementedFunc,
    buyWithBshare: notImplementedFunc,

    stakePhubrewardPool: notImplementedFunc,
    unstakePhubrewardPool: notImplementedFunc,
    exitPhubrewardPool: notImplementedFunc,
    getRewardPhubrewardPool: notImplementedFunc,

    getContract: notImplementedFunc,
    getRpcContract: notImplementedFunc,
});

const smartContractAddressMap: SmartContractAddressMap[] = [
    {
        chainId: 56,
        chainName: "mainnet",

        bbombContractAddress: "0x23efc2ff90e3423c3f84352b21b49fbcd4c3e32d",
        bbombContractAbi: require("./abi/ibep20.abi.json"),

        bombContractAddress: "0x522348779dcb2911539e76a1042aa922f9c47ee3",
        bombContractAbi: require("./abi/ibep20.abi.json"),
        btcbContractAddress: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
        btcbContractAbi: require("./abi/ibep20.abi.json"),
        routerContractAddress: "0x6664afefe82625e70cd44a5fd04a0a137a6dea49",
        routerContractAbi: require("./abi/router02.abi.json"),
        factoryContractAddress: "0x9c8e458e58bdae8d8e20c21a6f3bcbed49f3ee85",
        factoryContractAbi: require("./abi/factory.abi.json"),

        zapperContractAddress: "0x6D7954dDFdE2dA99030Be6ae824fEC1D65005bb1",
        zapperContractAbi: require("./abi/zapper.abi.json"),

        zapEstimatorContractAddress: "0xe6a842252096d39f777583b49f697fcf760b8a68",
        zapEstimatorContractAbi: require("./abi/zapestimator.abi.json"),

        priceOracleAddress: "0x952e1b7ded0f7ddebcc90b6ac9b035a003d7c3c2",
        priceOracleAbi: require("./abi/priceoracle.abi.json"),

        phubFeeDistributorAddress: "0xA9deF29dB63ef56E1AEE4a695109911DDEd8C644",
        phubFeeDistributorAbi: require("./abi/phubfeedistributor.abi.json"),

        taroTokenAbi: require("./abi/tarottoken.abi.json"),

        spookyRouterAbi: require("./abi/spookyrouter.abi.json"),

        phubpresaleAddress: "0x059f00A603b97624d9cA267Abc8C40883705d2ef",
        phubpresaleAbi: require("./abi/phubpresale.abi.json"),

        phubrewardpoolabi: require("./abi/phubrewardpool.abi.json"),
    }
];

function getChainAddresses(chainId: number): SmartContractAddressMap {
    var chain = smartContractAddressMap.filter((x) => x.chainId == chainId)[0];
    return chain;
}

function toPlainString(num: any) {
    return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
        function (a, b, c, d, e) {
            return e < 0
                ? b + '0.' + Array(1 - e - c.length).join('0') + c + d
                : b + c + d + Array(e - d.length + 1).join('0');
        });
}

const Web3Provider: React.FC<Web3ProviderProps> = (props) => {
    var _contracts: any = {};
    var _rpcContracts: any = {};

    //subgraph
    const [lendingGraph, setLendingGraph] = useState<LendingGraphResponse | null>(null);
    const [userPositions, setUserPositions] = useState<UserPositionsResponse | null>(null);
    const [farmApyStats, setFarmApyStats] = useState<BeefyFarmApyStatsResponse | null>(null);
    const [lpStats, setLpStats] = useState<LpStats | null>(null);
    // const [farmTvlStats, setFarmTvlStats] = useState<BeefyFarmTvlStatsResponse | null>(null);

    const [currentChainId, setCurrentChainId] = useState(56);
    const [web3Connection, setWeb3Connection] = useState<Web3>();
    const [web3Connected, setWeb3Connected] = useState(false);
    const [walletAddress, setWalletAddress] = useState("");
    const [providerName, setProviderName] = useState("");

    //pricing
    const [bnbBalance, setBnbBalance] = useState(0);
    const [bnbUsdtPrice, setbnbUsdtPrice] = useState(0);

    //flags
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    const [transak, setTransak] = useState<{ "ApiKey": string, "Environment": string }>({
        ApiKey: "11eca9d2-ee0e-46d9-9ae4-324cde64c371",
        Environment: "PRODUCTION"
    });

    //STEP 0 - run loop for common data that doesn't require a wallet address
    useEffect(() => {
        fetchCommonData()
    }, []);

    //STEP 1 - once we have connected a wallet, get the address
    useEffect(() => {
        if (web3Connection != null) {
            log("useEffect[web3Connection]", "Loading wallet...");

            //get wallet address
            web3Connection.eth.getAccounts().then(function (accounts) {
                var account = accounts[0];
                log("useEffect[web3Connection]", `Account selected: ${account}`);

                if (account.toLowerCase() != walletAddress.toLowerCase()) {
                    setWalletAddress(account);
                    if (providerName != "walletconnect") {
                        localStorage.setItem('lastWalletAddress', account);
                        localStorage.setItem('lastWalletProvider', providerName);
                    }
                }
            });
        }
    }, [web3Connection]);


    //STEP 2 - once we have set the wallet, we'll enumerate all the plans from the contracts
    useEffect(() => {
        if (web3Connected == true && web3Connection != null) {
            fetchWalletData(walletAddress);
        }
    }, [walletAddress]);

    //reviving last wallet address
    useEffect(() => {
        var address = localStorage.getItem('lastWalletAddress') || '';
        var walletProvider = localStorage.getItem('lastWalletProvider') || '';

        if (address != '') {
            connectWeb3(walletProvider);
        }
    }, []);

    function getRpcContract(address: string, abi: any, skipCache: boolean = false): any {
        if (_rpcContracts[address] == null) {
            const url = "https://rpc.ankr.com/bsc";
            const provider = new Web3(new Web3.providers.HttpProvider(url));

            const contract = new provider.eth.Contract(abi, address);
            _rpcContracts[address] = contract;

        }
        return _rpcContracts[address];
    }


    function getContract(address: string, abi: any, skipCache: boolean = false): any {
        if (web3Connected == true && web3Connection != null && address != "") {
            if (_contracts[address] == null || skipCache == true) {

                const contract = new web3Connection.eth.Contract(abi, address);
                _contracts[address] = contract;

            }
            return _contracts[address];
        }
        return null;
    }

    async function connectWeb3(mode: string = "walletconnect") {
        var web3: any;
        var provider: any;
        setProviderName(mode);

        if (mode == "walletconnect") {
            log("connectWeb3", "Initiating session with WalletConnect...");
            const WalletConnectProvider = require("@walletconnect/web3-provider");
            provider = new WalletConnectProvider.default({
                rpc: {
                    56: "https://bsc-dataseed.binance.org/",
                },
                chainId: 56,
                clientMeta: {
                    name: "bitBOMB",
                    description: "bitBOMB ecosystem of multi-peg algocoin protocols.",
                    url: "https://www.bitbomb.io",
                    icons: [
                        "<ADD ICON HERE>"
                    ]
                }
            });
        } else if (mode == 'fortmatic') {
            const Fortmatic = require('fortmatic');
            const BSCOptions = {
                /* Smart Chain mainnet RPC URL */
                rpcUrl: 'https://bsc-dataseed.binance.org/',
                chainId: 56 // Smart Chain mainnet chain id
            }
            let fm = new Fortmatic('pk_live_B4C01317DA4CF1F8', BSCOptions);
            provider = fm.getProvider();
        } else if (mode == 'torus') {
            const Torus = require("@toruslabs/torus-embed");
            const torus = new Torus();
            await torus.init({
                network: {
                    host: 'https://bsc-dataseed.binance.org/', // mandatory
                    chainId: 56, // optional
                    networkName: "BNB Chain", // optional
                }
            });
            await torus.login();
            provider = torus.provider;
        } else if (mode == "metamask") {
            log("connectWeb3", "Initiating session with MetaMask...");
            provider = (window as any).ethereum;
        }

        //instantiate web3 client
        var result = await provider.enable();
        log("connectWeb3", result);
        web3 = new Web3(provider);

        log("connectWeb3", "Web3 access granted!");
        if (web3 != null) {
            try {
                log("connectWeb3", "Web3 loaded correctly...");
                var chainId = await web3.eth.getChainId();
                const binanceChainId = 56;

                if (chainId !== binanceChainId) {
                    try {
                        await provider.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: '0x' + binanceChainId.toString(16) }],
                        });
                        log("connectWeb3", "You have succefully switched to Binance network")
                        setWeb3Connected(false);
                        setWalletAddress("");
                        localStorage.setItem('lastWalletAddress', "");
                        connectWeb3(mode);
                    } catch (switchError: any) {
                        // This error code indicates that the chain has not been added to MetaMask.
                        if (switchError.code === 4902) {
                            log("connectWeb3", "This network is not available in your metamask, please add it")
                            try {
                                await provider.request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: '0x' + binanceChainId.toString(16),
                                            chainName: 'BSC Mainnet',
                                            rpcUrls: ['https://bsc-dataseed.binance.org'],
                                            blockExplorerUrls: ['https://bscscan.com'],
                                            nativeCurrency: {
                                                name: 'BNB',
                                                symbol: 'BNB',
                                                decimals: 18,
                                            },
                                        }],
                                });
                                setWeb3Connected(false);
                                setWalletAddress("");
                                localStorage.setItem('lastWalletAddress', "");
                                connectWeb3(mode);
                            } catch (addError) {
                                // handle "add" error
                                log("connectWeb3", addError);
                            }
                        } else {
                            log("connectWeb3", switchError);
                            alert('Error switching chains to BSC: ' + switchError.message);
                        }
                    }

                    return;
                }

                setWeb3Connected(true);
                setWeb3Connection(web3 as any);
                setCurrentChainId(chainId);
                var chainDetails = getChainAddresses(chainId);
                if (!chainDetails) {
                    log("connectWeb3", "No chain details found...");
                    return;
                }

                //subscribe to provider events
                provider.on('accountsChanged', function (accounts: any) {
                    if (accounts.length > 0) {
                        web3 = null;
                        provider = null;

                        connectWeb3(mode);
                    } else {
                        disconnectWeb3();
                    }
                });
                provider.on('chainChanged', () => {
                    disconnectWeb3();
                })
                provider.on('disconnect', (code: number, reason: string) => {
                    console.log(code, reason);
                    disconnectWeb3();
                });

            } catch (e) {
                log("connectWeb3", "Web3 access denied...");
            }
        }
    }

    async function disconnectWeb3() {
        log("disconnectWeb3", `Disconnecting...`);
        setWeb3Connected(false);
        setWalletAddress("");
        localStorage.setItem('lastWalletAddress', "");
        window.location.reload();
    }

    //=======================================================================


    async function fetchWalletData(address: string) {
        //exit logic on loop in case address has changed in web3 provider
        if (address.toLowerCase() != walletAddress.toLowerCase()) {
            return;
        }
        if (web3Connection != null) {
            var addresses = await web3Connection.eth.getAccounts();
            if (addresses[0].toLowerCase() != address.toLowerCase()) {
                return;
            }
        }

        const chainContext = getChainAddresses(currentChainId);

        //functions to refresh data
        // fetchAdmins();
        // fetchBnbBalance();
        // fetchBnbUsdtTicker();

        // setUserPositions(await getUserPositions(address.toLowerCase()));

        // fetchApprovalAmount(chainContext.bbombContractAddress, walletAddress, chainContext.routerContractAddress, 18, setRouterBbombApprovalAmount);
        // fetchApprovalAmount(chainContext.btcbContractAddress, walletAddress, chainContext.routerContractAddress, 18, setRouterBtcbApprovalAmount);
        // fetchApprovalAmount(chainContext.bombContractAddress, walletAddress, chainContext.routerContractAddress, 18, setRouterBombApprovalAmount);

        // setLpStats({
        //     'BOMB-BTCB-LP': await getLpStat('0xadba179f3ac7856ac81664acca96520912e8e8b9', '0x9da55ec7aeef5ea7e78c9da849d47540fe3cf4ba', '0x84392649eb0bC1c1532F2180E58Bae4E1dAbd8D6', '0x522348779DCb2911539e76A1042aA922F9C47Ee3', '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 10000),
        //     'BUSM-BUSD-LP': await getLpStat('0x056cad8d88e5b5a41f710af4cc70e4d9c65db3bc', '0xe1e3b06037e4d7f2ade340002728280647572d26', '0xee46bd06a8876c3cc86027dc7d2df19af513cd12', '0x6216b17f696b14701e17bcb24ec14430261be94a', '0xe9e7cea3dedca5984780bafc599bd69add087d56', 1)
        // });

        //re-run the loop
        setTimeout(() => fetchWalletData(address), 20000);
    }

    async function fetchCommonData() {
        // setLendingGraph(await getLendingPool());
        // setFarmApyStats(await getFarmApyStats());
        // setFarmTvlStats(await getFarmTvlStats());

        //re-run the loop
        setTimeout(() => fetchCommonData(), 20000);
    }

    async function fetchBnbBalance() {
        if (web3Connected == true && web3Connection != null) {
            log("fetchBnbBalance", "Retrieving wallet token balance...");

            var balance = await web3Connection.eth.getBalance(walletAddress);
            log("fetchBnbBalance", balance);

            var balanceNumber = Number(balance);
            log("fetchBnbBalance", `Input: ${balanceNumber}`);

            setBnbBalance(balanceNumber / (1000000000000000000));
            log("fetchBnbBalance", `Adjusted: ${bnbBalance}`);
        }
    }

    async function getLpStat(collateralAddress: string, selectedUniswapV2PairAddress: string, poolAddress: string, token0Address: string, token1Address: string, ratio: number): Promise<LpStat> {
        if (web3Connection != null) {
            const chainContext = getChainAddresses(currentChainId);

            const collateralAbi = require("./abi/collateral.abi.json");
            const collateralContract = new web3Connection.eth.Contract(collateralAbi, collateralAddress);
            const tokenAbi = require("./abi/ibep20.abi.json");
            const token0 = new web3Connection.eth.Contract(tokenAbi, token0Address);
            const token1 = new web3Connection.eth.Contract(tokenAbi, token1Address);
            const oracleContract = getContract(chainContext.priceOracleAddress, chainContext.priceOracleAbi);
            const lpAbi = require("./abi/pancakepair.abi.json");
            const lpToken = new web3Connection.eth.Contract(lpAbi, poolAddress);
            const pancakeswapPair = new web3Connection.eth.Contract(lpAbi, selectedUniswapV2PairAddress);

            const lpTotalSupply = await pancakeswapPair.methods.totalSupply().call({ from: walletAddress });
            const lpReserves = await pancakeswapPair.methods.getReserves().call({ from: walletAddress });

            const token0Digits = await token0.methods.decimals().call({ from: walletAddress });
            const token1Digits = await token1.methods.decimals().call({ from: walletAddress });
            const lpDigits = await lpToken.methods.decimals().call({ from: walletAddress });

            const lpTokenSupply = await lpToken.methods.totalSupply().call({ from: walletAddress }) / Math.pow(10, lpDigits);

            const token0Amount = await token0.methods.balanceOf(poolAddress).call({ from: walletAddress }) / Math.pow(10, token0Digits);
            const token1Amount = await token1.methods.balanceOf(poolAddress).call({ from: walletAddress }) / Math.pow(10, token1Digits);
            const token0AmountInOneLP = token0Amount / lpTokenSupply;
            const token1AmountInOneLP = token1Amount / lpTokenSupply;

            const priceDenomLP = await collateralContract.methods.getPrices().call({ from: walletAddress });
            const token0DenomLPPrice = priceDenomLP[0] / 1e18 / 1e18 * Math.pow(10, token0Digits);
            const token1DenomLPPrice = priceDenomLP[1] / 1e18 / 1e18 * Math.pow(10, token1Digits);

            const twapPriceObj = await oracleContract.methods.getResult(selectedUniswapV2PairAddress).call({ from: walletAddress });
            const twapPrice = twapPriceObj[0] / 2 ** 112 * Math.pow(10, token0Digits) / Math.pow(10, token1Digits);

            return {
                token0Amount: token0AmountInOneLP,
                token1Amount: token1AmountInOneLP,
                token0DenomLPPrice: token0DenomLPPrice,
                token1DenomLPPrice: token1DenomLPPrice,
                twap: twapPrice,
                ratio: ratio,
                totalSupply: lpTotalSupply,
                reserve0: lpReserves[0],
                reserve1: lpReserves[1],
            };
        }

        return {
            token0Amount: 0,
            token1Amount: 0,
            token0DenomLPPrice: 0,
            token1DenomLPPrice: 0,
            twap: 1,
            ratio: 1,
            totalSupply: 0,
            reserve0: 0,
            reserve1: 0
        };
    }

    async function fetchAdmins() {
        //ideally retrieve this from an external contract such as multi-sig wallet
        var admins: string[] = [
            "0x9bf0800193825e3389B14EB69abDE07dd932b6AC" //jourdan
        ];

        var match = admins.filter((x) => x.toLowerCase() == walletAddress.toLowerCase());
        if (match.length > 0) {
            setIsAdmin(true);
        }
    }

    async function approveContract(baseContract: string, walletAddress: string, spender: string, amount: string = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff") {
        if (web3Connection != null) {
            const abi = require("./abi/ibep20.abi.json");
            const contract = new web3Connection.eth.Contract(abi, baseContract);

            await contract.methods.approve(spender, amount).send({ from: walletAddress });
        }
    }

    async function fetchApprovalAmount(baseContract: string, walletAddress: string, spender: string, decimals: number, setFunction: (amount: number) => void) {
        if (web3Connection != null) {
            const abi = require("./abi/ibep20.abi.json");
            const contract = new web3Connection.eth.Contract(abi, baseContract);

            var approvalAmount = await contract.methods.allowance(walletAddress, spender).call();
            setFunction(approvalAmount / Math.pow(10, decimals));

            return approvalAmount;
        }
    }

    async function stakeAsset(poolToken: string, amount: number) {
        const chainContext = getChainAddresses(currentChainId);
        const contract = getContract(chainContext.routerContractAddress, chainContext.routerContractAbi);

        var adjustedAmount = toPlainString(Math.floor(Math.abs(amount * Math.pow(10, 18))));
        log("stakeAsset", `Amount adjusted:  ${amount} => ${adjustedAmount}`);

        var now = new Date(Date.now());
        var utcNow = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
        var deadline = Number(utcNow.toString().substring(0, 10)) + 1000000;

        await contract.methods.mint(poolToken, adjustedAmount, walletAddress, deadline).send({ from: walletAddress });
        fetchCommonData();
        await fetchWalletData(walletAddress);
    }

    async function unstakeAsset(poolToken: string, amount: number) {
        const chainContext = getChainAddresses(currentChainId);
        const contract = getContract(chainContext.routerContractAddress, chainContext.routerContractAbi);

        var adjustedAmount = toPlainString(Math.floor(Math.abs(amount * Math.pow(10, 18))));
        log("unstakeAsset", `Amount adjusted:  ${amount} => ${adjustedAmount}`);

        var now = new Date(Date.now());
        var utcNow = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
        var deadline = Number(utcNow.toString().substring(0, 10)) + 1000000;

        await contract.methods.redeem(poolToken, adjustedAmount, walletAddress, deadline, "0x").send({ from: walletAddress });
        fetchCommonData();
        await fetchWalletData(walletAddress);
    }

    async function depositCollateral(poolToken: string, amount: number) {
        const chainContext = getChainAddresses(currentChainId);
        const contract = getContract(chainContext.routerContractAddress, chainContext.routerContractAbi);

        var adjustedAmount = toPlainString(Math.floor(Math.abs(amount * Math.pow(10, 18))));
        log("depositCollateral", `Amount adjusted:  ${amount} => ${adjustedAmount}`);

        var now = new Date(Date.now());
        var utcNow = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
        var deadline = Number(utcNow.toString().substring(0, 10)) + 1000000;

        await contract.methods.mintCollateral(poolToken, adjustedAmount, walletAddress, deadline, []).send({ from: walletAddress });
        fetchCommonData();
        await fetchWalletData(walletAddress);
    }

    async function withdrawCollateral(poolToken: string, amount: number) {
        const chainContext = getChainAddresses(currentChainId);
        const contract = getContract(chainContext.routerContractAddress, chainContext.routerContractAbi);

        var adjustedAmount = toPlainString(Math.floor(Math.abs(amount * Math.pow(10, 18))));
        log("withdrawCollateral", `Amount adjusted:  ${amount} => ${adjustedAmount}`);

        var now = new Date(Date.now());
        var utcNow = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
        var deadline = Number(utcNow.toString().substring(0, 10)) + 1000000;

        await contract.methods.redeem(poolToken, adjustedAmount, walletAddress, deadline, []).send({ from: walletAddress });
        fetchCommonData();
        await fetchWalletData(walletAddress);
    }

    async function leveragePool(poolToken: string, amountADesired: number, amountBDesired: number, amountAMin: number, amountBMin: number) {
        const chainContext = getChainAddresses(currentChainId);
        const contract = getContract(chainContext.routerContractAddress, chainContext.routerContractAbi);

        var adjustedAmountADesired = toPlainString(Math.floor(Math.abs(amountADesired * Math.pow(10, 18))));
        log("leveragePool", `adjustedAmountADesired adjusted:  ${amountADesired} => ${adjustedAmountADesired}`);
        var adjustedAmountBDesired = toPlainString(Math.floor(Math.abs(amountBDesired * Math.pow(10, 18))));
        log("leveragePool", `adjustedAmountBDesired adjusted:  ${amountBDesired} => ${adjustedAmountBDesired}`);
        var adjustedAmountAMin = toPlainString(Math.floor(Math.abs(amountAMin * Math.pow(10, 18))));
        log("leveragePool", `adjustedAmountAMin adjusted:  ${amountAMin} => ${adjustedAmountAMin}`);
        var adjustedAmountBMin = toPlainString(Math.floor(Math.abs(amountBMin * Math.pow(10, 18))));
        log("leveragePool", `adjustedAmountBMin adjusted:  ${amountBMin} => ${adjustedAmountBMin}`);

        var now = new Date(Date.now());
        var utcNow = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
        var deadline = Number(utcNow.toString().substring(0, 10)) + 1000000;

        await contract.methods.leverage(poolToken, adjustedAmountADesired, adjustedAmountBDesired, adjustedAmountAMin, adjustedAmountBMin, walletAddress, deadline, [], []).send({ from: walletAddress });
        fetchCommonData();
        await fetchWalletData(walletAddress);
    }

    async function deleveragePool(poolToken: string, redeemTokens: number, amountAMin: number, amountBMin: number) {
        const chainContext = getChainAddresses(currentChainId);
        const contract = getContract(chainContext.routerContractAddress, chainContext.routerContractAbi);

        var adjustedRedeemTokens = toPlainString(Math.floor(Math.abs(redeemTokens * Math.pow(10, 18))));
        log("deleveragePool", `adjustedRedeemTokens adjusted:  ${redeemTokens} => ${adjustedRedeemTokens}`);
        var adjustedAmountAMin = toPlainString(Math.floor(Math.abs(amountAMin * Math.pow(10, 18))));
        log("deleveragePool", `adjustedAmountAMin adjusted:  ${amountAMin} => ${adjustedAmountAMin}`);
        var adjustedAmountBMin = toPlainString(Math.floor(Math.abs(amountBMin * Math.pow(10, 18))));
        log("deleveragePool", `adjustedAmountBMin adjusted:  ${amountBMin} => ${adjustedAmountBMin}`);

        var now = new Date(Date.now());
        var utcNow = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
        var deadline = Number(utcNow.toString().substring(0, 10)) + 1000000;

        await contract.methods.deleverage(poolToken, adjustedRedeemTokens, adjustedAmountAMin, adjustedAmountBMin, deadline, []).send({ from: walletAddress });
        fetchCommonData();
        await fetchWalletData(walletAddress);
    }

    async function fetchAssetBalance(poolToken: string): Promise<number> {
        if (web3Connection != null) {
            const chainContext = getChainAddresses(currentChainId);
            const contract = getContract(poolToken, chainContext.bombContractAbi);

            if (!contract) {
                return 0;
            }

            var digits: number = await contract.methods.decimals().call({ from: walletAddress });
            // console.log(digits);
            var balance: number = await contract.methods.balanceOf(walletAddress).call({ from: walletAddress });
            // console.log(balance);
            var adjustedBalance = balance / Math.pow(10, digits) - (1 / Math.pow(10, 7));
            if (adjustedBalance < 0) {
                adjustedBalance = 0;
            }

            return adjustedBalance;
        }

        return 0;
    }

    async function fetchPhubApr(phubrewardpooladdress: string, price: number, totalStakedUsd: number) {
        const chainContext = getChainAddresses(currentChainId);
        const contract = getRpcContract(phubrewardpooladdress, chainContext.phubrewardpoolabi);

        const BLOCKS_PER_DAY = 28800;
        const yearlyRewards = await contract.methods.rewardRate().call() * 3 * BLOCKS_PER_DAY * 365 / (10 ** 18);
        const yearlyRewardsUsd = yearlyRewards * price;

        return yearlyRewardsUsd / totalStakedUsd;
    }

    async function fetchPhubTvl(phubrewardpooladdress: string) {
        const chainContext = getChainAddresses(currentChainId);
        const contract = getRpcContract(phubrewardpooladdress, chainContext.phubrewardpoolabi);

        return (await contract.methods.totalSupply().call() / (10 ** 18));
    }

    async function fetchPhubStakedBalance(phubrewardpooladdress: string): Promise<number> {
        if (web3Connection != null) {
            const chainContext = getChainAddresses(currentChainId);
            const contract = getContract(phubrewardpooladdress, chainContext.phubrewardpoolabi);

            if (!contract) {
                return 0;
            }

            var balance: number = await contract.methods.balanceOf(walletAddress).call({ from: walletAddress });
            // console.log(balance);
            const digits = 18;
            var adjustedBalance = balance / Math.pow(10, digits)/* - (1 / Math.pow(10, 7))*/;
            if (adjustedBalance < 0) {
                adjustedBalance = 0;
            }

            return adjustedBalance;
        }

        return 0;
    }


    async function fetchPhubBoughtBack() {
        const chainContext = getChainAddresses(currentChainId);
            const contract = getRpcContract(chainContext.phubFeeDistributorAddress, chainContext.phubFeeDistributorAbi, true);

        return (await contract.methods.totalBought().call() / (10 ** 18));
    }

    // async function fetchPhubBoughtBack(): Promise<number> {
    //     if (web3Connection != null) {
    //         const chainContext = getChainAddresses(currentChainId);
    //         const contract = getRpcContract(chainContext.phubFeeDistributorAddress, chainContext.phubFeeDistributorAbi, true);

    //         if (!contract) {
    //             return 0;
    //         }

    //         var balance: number = await contract.methods.totalBought().call({ from: walletAddress });
    //         console.log("balance", balance)
    //         // console.log(balance);
    //         const digits = 18;
    //         var adjustedBalance = balance / Math.pow(10, digits);
    //         if (adjustedBalance < 0) {
    //             adjustedBalance = 0;
    //         }

    //         return adjustedBalance;
    //     }

    //     return 0;
    // }


    async function fetchPhubEarnedRewards(phubrewardpooladdress: string): Promise<number> {
        if (web3Connection != null) {
            const chainContext = getChainAddresses(currentChainId);
            const contract = getContract(phubrewardpooladdress, chainContext.phubrewardpoolabi);

            if (!contract) {
                return 0;
            }

            var balance: number = await contract.methods.rewards(walletAddress).call({ from: walletAddress });
            // console.log(balance);
            const digits = 18;
            var adjustedBalance = balance / Math.pow(10, digits);
            if (adjustedBalance < 0) {
                adjustedBalance = 0;
            }

            return adjustedBalance;
        }

        return 0;
    }

    async function fetchAssetExchangeRate(poolToken: string): Promise<number> {
        if (web3Connection != null) {
            const chainContext = getChainAddresses(currentChainId);
            const contract = getContract(poolToken, chainContext.taroTokenAbi, true);

            var ret = 1;
            var underlyingAddress: string = await contract.methods["0x6f307dc3"]().call({ from: walletAddress });
            if (underlyingAddress != "") {
                var underlyingContract = getContract(underlyingAddress, chainContext.taroTokenAbi, true);
                var underlyingRate = await underlyingContract.methods.exchangeRate().call({ from: walletAddress });
                var digits = await underlyingContract.methods.decimals().call({ from: walletAddress });
                var adjustedUnderlyingRate = underlyingRate / Math.pow(10, digits);
                ret *= adjustedUnderlyingRate;
            }

            return ret;
        }

        return 0;
    }

    async function fetchApprovalAmountForRouter(tokenAddress: string, decimals: number = 18): Promise<number> {
        const chainContext = getChainAddresses(currentChainId);
        var amount = await fetchApprovalAmount(tokenAddress, walletAddress, chainContext.routerContractAddress, decimals, () => {
        });
        var adjustedAmount = amount / (10 ** decimals);
        return adjustedAmount;
    }

    async function fetchApprovalAmountForSpender(tokenAddress: string, spenderAddress: string, decimals: number = 18): Promise<number> {
        var amount = await fetchApprovalAmount(tokenAddress, walletAddress, spenderAddress, decimals, () => {
        });
        var adjustedAmount = amount / (10 ** decimals);
        return adjustedAmount;
    }

    async function approveAmountForRouter(tokenAddress: string, decimals: number = 18) {
        const chainContext = getChainAddresses(currentChainId);
        await approveContract(tokenAddress, walletAddress, chainContext.routerContractAddress);
        await fetchWalletData(walletAddress);
    }

    async function approveAmountForSpender(tokenAddress: string, spenderAddress: string, decimals: number = 18) {
        await approveContract(tokenAddress, walletAddress, spenderAddress);
        await fetchWalletData(walletAddress);
    }

    async function zapIn(toTokenAddress: string, amount: number, outputAmountMin: number) {
        const chainContext = getChainAddresses(currentChainId);
        const contract = getContract(chainContext.zapperContractAddress, chainContext.zapperContractAbi);

        var adjustedAmount = toPlainString(Math.floor(Math.abs(amount * Math.pow(10, 18))));
        log("zapIn", `adjustedAmount adjusted:  ${amount} => ${adjustedAmount}`);
        var adjustedOutputAmountMin = toPlainString(Math.floor(Math.abs(outputAmountMin * Math.pow(10, 18))));
        log("zapIn", `adjustedOutputAmountMin adjusted:  ${outputAmountMin} => ${adjustedOutputAmountMin}`);

        await contract.methods.zapIn(toTokenAddress, adjustedOutputAmountMin).send({
            from: walletAddress,
            value: adjustedAmount
        });
        fetchCommonData();
        await fetchWalletData(walletAddress);
    }

    async function zapInToken(fromTokenAddress: string, toTokenAddress: string, amount: number, outputAmountMin: number) {
        const chainContext = getChainAddresses(currentChainId);
        const contract = getContract(chainContext.zapperContractAddress, chainContext.zapperContractAbi);

        var adjustedAmount = toPlainString(Math.floor(Math.abs(amount * Math.pow(10, 18))));
        log("zapInToken", `adjustedAmount adjusted:  ${amount} => ${adjustedAmount}`);
        var adjustedOutputAmountMin = toPlainString(Math.floor(Math.abs(outputAmountMin * Math.pow(10, 18))));
        log("zapInToken", `adjustedOutputAmountMin adjusted:  ${outputAmountMin} => ${adjustedOutputAmountMin}`);

        await contract.methods.zapInToken(fromTokenAddress, adjustedAmount, toTokenAddress, adjustedOutputAmountMin).send({ from: walletAddress });
        fetchCommonData();
        await fetchWalletData(walletAddress);
    }

    async function fetchPhubPresaleData() {
        const chainContext = getChainAddresses(currentChainId);
        const phubPresaleContract = getRpcContract(chainContext.phubpresaleAddress, chainContext.phubpresaleAbi);

        return {
            totalTokens: 6000,
            soldTokens: 6000,
            availableTokens: 0,
            // totalTokens: await phubPresaleContract.methods.totalTokens().call() / (10 ** 18),
            // soldTokens: await phubPresaleContract.methods.soldTokens().call() / (10 ** 18),
            // availableTokens: await phubPresaleContract.methods.availableTokens().call() / (10 ** 18),
            //startTime: await phubPresaleContract.methods.startTime().call(),
            startTime: 1655485200,
        };
    }

    async function buyWithBomb(token: string, amount: number) {
        // const chainContext = getChainAddresses(currentChainId);
        const contract = getContract('0x5eafE3d8d21f2b9F2597840E29A7D352181CCF2A', require('./abi/bitbombswap.abi.json'));

        var adjustedAmount = toPlainString(Math.floor(Math.abs(amount * Math.pow(10, 18))));
        log("buyWithBomb", `adjustedAmount adjusted:  ${amount} => ${adjustedAmount}`);

        await contract.methods.buyWithBomb(token, adjustedAmount).send({ from: walletAddress });
        fetchCommonData();
        await fetchWalletData(walletAddress);
    }

    async function buyWithBbond(token: string, amount: number) {
        // const chainContext = getChainAddresses(currentChainId);
        const contract = getContract('0x5eafE3d8d21f2b9F2597840E29A7D352181CCF2A', require('./abi/bitbombswap.abi.json'));

        var adjustedAmount = toPlainString(Math.floor(Math.abs(amount * Math.pow(10, 18))));
        log("buyWithBbond", `adjustedAmount adjusted:  ${amount} => ${adjustedAmount}`);

        await contract.methods.buyWithBbond(token, adjustedAmount).send({ from: walletAddress });
        fetchCommonData();
        await fetchWalletData(walletAddress);
    }

    async function buyWithBshare(token: string, amount: number) {
        // const chainContext = getChainAddresses(currentChainId);
        const contract = getContract('0x732BDEf513Ba8dbf68b878450D46380ee0Ed271D', require('./abi/bitshareswap.abi.json'));

        var adjustedAmount = toPlainString(Math.floor(Math.abs(amount * Math.pow(10, 18))));
        log("buyWithBbond", `adjustedAmount adjusted:  ${amount} => ${adjustedAmount}`);

        await contract.methods.buyWithBshare(adjustedAmount).send({ from: walletAddress });
        fetchCommonData();
        await fetchWalletData(walletAddress);
    }

    async function stakePhubrewardPool(phubrewardpooladdress: string, amount: number) {
        const chainContext = getChainAddresses(currentChainId);
        const contract = getContract(phubrewardpooladdress, chainContext.phubrewardpoolabi);

        var adjustedAmount = toPlainString(Math.floor(Math.abs(amount * Math.pow(10, 18))));
        log("buyWithBbond", `adjustedAmount adjusted:  ${amount} => ${adjustedAmount}`);

        await contract.methods.stake(adjustedAmount).send({ from: walletAddress });
        fetchCommonData();
        await fetchWalletData(walletAddress);
    }

    async function unstakePhubrewardPool(phubrewardpooladdress: string, amount: number) {
        const chainContext = getChainAddresses(currentChainId);
        const contract = getContract(phubrewardpooladdress, chainContext.phubrewardpoolabi);

        var adjustedAmount = toPlainString(Math.floor(Math.abs(amount * Math.pow(10, 18))));
        log("buyWithBbond", `adjustedAmount adjusted:  ${amount} => ${adjustedAmount}`);

        await contract.methods.withdraw(adjustedAmount).send({ from: walletAddress });
        fetchCommonData();
        await fetchWalletData(walletAddress);
    }

    async function exitPhubrewardPool(phubrewardpooladdress: string) {
        const chainContext = getChainAddresses(currentChainId);
        const contract = getContract(phubrewardpooladdress, chainContext.phubrewardpoolabi);

        await contract.methods.exit().send({ from: walletAddress });
        fetchCommonData();
        await fetchWalletData(walletAddress);
    }

    async function getRewardPhubrewardPool(phubrewardpooladdress: string) {
        const chainContext = getChainAddresses(currentChainId);
        const contract = getContract(phubrewardpooladdress, chainContext.phubrewardpoolabi);

        await contract.methods.getReward().send({ from: walletAddress });
        fetchCommonData();
        await fetchWalletData(walletAddress);
    }

    async function getTokenPriceFromPancakeswapInDollars(tokenAddress: string): Promise<number> {
        const spookyRouterAddr = '0x10ED43C718714eb63d5aA57B78B54704E256024E';
        const chainContext = getChainAddresses(currentChainId);
        const spookyRouter = getRpcContract(spookyRouterAddr, chainContext.spookyRouterAbi);

        if (tokenAddress === '0x522348779DCb2911539e76A1042aA922F9C47Ee3') {
            const estimate = await spookyRouter.methods.getAmountsOut(1000000000, ['0x522348779DCb2911539e76A1042aA922F9C47Ee3', '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56']).call();

            return estimate[estimate.length - 1] / (10 ** 9);
        }

        if (tokenAddress === '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c') {
            const estimate = await spookyRouter.methods.getAmountsOut(1000000000, ['0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56']).call();

            return estimate[estimate.length - 1] / (10 ** 9);
        }

        if (tokenAddress === '0x95a6772a2272b9822d4b3dfeeaedf732f1d28db8' || tokenAddress === '0x95A6772a2272b9822D4b3DfeEaedF732F1D28DB8') {
            const estimate = await spookyRouter.methods.getAmountsOut(1000000000, ['0x95a6772a2272b9822d4b3dfeeaedf732f1d28db8', '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56']).call();

            return estimate[estimate.length - 1] / (10 ** 9);
        }

        throw new Error('Unsupported token ' + tokenAddress);
    }

    return (
        <Web3Context.Provider
            value={{
                Web3Connection: web3Connection,
                currentChainId: currentChainId,
                connectWeb3: connectWeb3,
                disconnectWeb3: disconnectWeb3,
                web3Connected: web3Connected,
                walletAddress: walletAddress,
                providerName: providerName,

                isAdmin: isAdmin,

                transak: transak,

                fetchApprovalAmountForRouter: fetchApprovalAmountForRouter,
                fetchApprovalAmountForSpender: fetchApprovalAmountForSpender,
                approveAmountForRouter: approveAmountForRouter,
                approveAmountForSpender: approveAmountForSpender,

                bnbBalance: bnbBalance,
                bnbUsdtPrice: bnbUsdtPrice,

                lendingGraph: lendingGraph,
                userPositions: userPositions,
                farmApyStats: farmApyStats,
                // farmTvlStats: farmTvlStats,
                lpStats: lpStats,

                approveAsset: approveAmountForRouter,
                fetchAssetApprovalAmount: fetchApprovalAmountForRouter,
                fetchAssetBalance: fetchAssetBalance,
                fetchPhubStakedBalance: fetchPhubStakedBalance,
                fetchAssetExchangeRate: fetchAssetExchangeRate,
                fetchPhubEarnedRewards: fetchPhubEarnedRewards,
                fetchPhubBoughtBack: fetchPhubBoughtBack,

                fetchPhubApr: fetchPhubApr,
                fetchPhubTvl: fetchPhubTvl,


                stakeAsset: stakeAsset,
                unstakeAsset: unstakeAsset,
                depositCollateral: depositCollateral,
                withdrawCollateral: withdrawCollateral,
                leveragePool: leveragePool,
                deleveragePool: deleveragePool,

                zapIn: zapIn,
                zapInToken: zapInToken,

                getTokenPriceFromPancakeswapInDollars: getTokenPriceFromPancakeswapInDollars,

                fetchPhubPresaleData: fetchPhubPresaleData,
                buyWithBomb: buyWithBomb,
                buyWithBbond: buyWithBbond,
                buyWithBshare: buyWithBshare,

                stakePhubrewardPool: stakePhubrewardPool,
                unstakePhubrewardPool: unstakePhubrewardPool,
                exitPhubrewardPool: exitPhubrewardPool,
                getRewardPhubrewardPool: getRewardPhubrewardPool,

                getContract: getContract,
                getRpcContract: getRpcContract,
            }}
        >
            {props.children}
        </Web3Context.Provider>
    );
}

export default Web3Provider;
