import React, { useContext, useEffect } from "react";
import { Web3Context } from "../Components/Web3Context/Web3Context";
import logo from "./logo.png";
import SocialBar from "../Components/Social/SocialBar";
import { useLocation } from 'react-router-dom';
import PresaleBuyCard from "../Cards/PresaleBuyCard";
import Countdown from "react-countdown";
import moment from "moment";

function HomePage() {
    const search = useLocation().search;

    useEffect(() => {
        const vconsole = new URLSearchParams(search).get("vconsole");
        if (vconsole === 'yes') {
            const VConsole = require("vconsole");
            const vConsole = new VConsole({ theme: 'dark' });
        }
    }, []);

    const startTime = '1657728000';
    const endTime = '1657814400';
    const now = moment();
    const presaleStartDate = moment.unix(Number(startTime));
    const presaleEndDate = moment.unix(Number(endTime));

    const fromTokensPeg: { [key: string]: { symbol: string, decimals: number } } = {
        '0x522348779DCb2911539e76A1042aA922F9C47Ee3': { 'symbol': 'BOMB', 'decimals': 18 },
        '0xDA1d9C79240003195d0a67f202efcCCC3F78b994': { 'symbol': 'BBOND', 'decimals': 18 },
    }

    const fromTokensShare: { [key: string]: { symbol: string, decimals: number } } = {
        '0x531780FAcE85306877D7e1F05d713D1B50a37F7A': { 'symbol': 'BSHARE', 'decimals': 18 },
    }

    const assets = [
        // {
        //     'name': 'bitBTC',
        //     'address': '0x39739A969ff667C91a81252b3DDDFAe31aaE4Aeb',
        //     'spender': '0x5eafE3d8d21f2b9F2597840E29A7D352181CCF2A',
        //     'fromTokens': fromTokensPeg,
        // },
        // {
        //     'name': 'bitDOT',
        //     'address': '0x123c62f04c9D70bD6F04E6282434c728F04eBD50',
        //     'spender': '0x5eafE3d8d21f2b9F2597840E29A7D352181CCF2A',
        //     'fromTokens': fromTokensPeg,
        // },
        // {
        //     'name': 'bitATOM',
        //     'address': '0x3CdC14690B4930f8b61A2195062f5AB8A6beC48d',
        //     'spender': '0x5eafE3d8d21f2b9F2597840E29A7D352181CCF2A',
        //     'fromTokens': fromTokensPeg,
        // },
        // {
        //     'name': 'bitADA',
        //     'address': '0x9F45C43c5Ed3c1a7FD7fb9f3b611860AE4dF2453',
        //     'spender': '0x5eafE3d8d21f2b9F2597840E29A7D352181CCF2A',
        //     'fromTokens': fromTokensPeg,
        // },
        {
            'name': 'bitSHARE',
            'address': '0x116B19FBAFCc947E7Ffcc3A42ff7185589143a56',
            'spender': '0x732BDEf513Ba8dbf68b878450D46380ee0Ed271D',
            'fromTokens': fromTokensShare,
        },
    ];

    return (
        <>
            <main className="relative flex flex-col flex-1 w-100 focus:outline-none dark:text-light">
                <div>
                    <img src={logo} className="mx-auto w-96" />
                </div>
                <h1 className="mb-4 text-2xl font-semibold text-left text-center text-black-500 sm:text-3xl md:text-4xl">
                    Welcome to {" "}
                    <span className="italic font-extrabold whitespace-nowrap">
                        <span className="text-primary-500">//</span> BitBomb{" "}
                        <span className="text-primary-500">//</span>
                    </span>
                    {" "} migration
                </h1>

                <div className="mb-6 font-bold text-center">
                    <div>Migrate your BOMB or BBOND assets into any of the new bitBOMB assets.</div>
                    <div>This migration will run for 24 hours or until the available assets are exhausted.</div>

                    {/*<div className="mt-5 text-center">
                        <button
                            className="flex-1 rounded-full bg-gradient-to-b from-[#ffd702] to-[#ffd702] text-black uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#fee22e] focus:outline-none focus:ring focus:ring-[#fee22e] focus:ring-opacity-75 whitespace-nowrap">
                            <a target="_blank" href="https://swap.czpegs.com/swap?inputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c&outputCurrency=0x95A6772a2272b9822D4b3DfeEaedF732F1D28DB8">Buy
                                PHUB</a>
                        </button>
                    </div>*/}
                </div>
                {/*<div className="text-center mb-7">
                    <div className="inline-block w-64 mb-5 md:mr-10 md:mb-0 rounded-xl bg-dark2-500">
                        {presaleStartDate <= now ? (
                            <>
                                <div>Migration ends in</div>
                                <div><Countdown date={presaleEndDate.toDate()} /></div>
                            </>
                        ) : (
                            <>
                                <div>Migration starts in</div>
                                <div><Countdown date={presaleStartDate.toDate()} /></div>
                            </>
                        )}
                    </div>
                </div>*/}
                <div className="flex-1 mx-auto mt-2 rounded-lg md:mx-0 contents md:block">
                    <div
                        className="grid grid-cols-1 gap-12 mx-0 mx-auto md:grid-cols-1 lg:grid-cols-2">
                        {assets.map((asset, index) => (
                            <PresaleBuyCard
                                key={index}
                                title={'Exchange to ' + asset.name}
                                fromTokens={asset.fromTokens}
                                symbol={asset.name}
                                symbolAddress={asset.address}
                                spenderAddress={asset.spender}
                                amountElementId={"buy_" + asset.name}
                                startTime={startTime}
                            />
                        ))}
                    </div>
                </div>
                <SocialBar className="mt-12" />
            </main>
        </>
    );
}

export default HomePage;
