import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { round, roundAndFormatNumber } from "../Common/HelperFunctions";
import MiniEditablePrice from "../Components/CardControls/MiniEditablePrice";
import { Web3Context } from "../Components/Web3Context/Web3Context";
import ConnectButton from "../Components/ConnectButton/ConnectButton";
import Countdown from 'react-countdown';
import moment from "moment";

interface PresaleBuyCardProps {
    className?: string;
    featured?: boolean;
    amountElementId: string;
    title: string;
    fromTokens: {
        [key: string]: {
            symbol: string;
            decimals: number;
        };
    };
    spenderAddress: string;
    symbol: string;
    symbolAddress: string;
    startTime: string;
}

const PresaleBuyCard: FC<PresaleBuyCardProps> = (props) => {
    const {
        web3Connected,
        connectWeb3,
        disconnectWeb3,
        walletAddress,
        getRpcContract,
        approveAmountForSpender,
        fetchApprovalAmountForSpender,
        fetchAssetBalance,
        buyWithBomb,
        buyWithBbond,
        buyWithBshare,
    } = useContext(Web3Context);
    const [total, setTotal] = useState<number|null>(null);
    const [available, setAvailable] = useState<number|null>(null);
    const [sold, setSold] = useState<number|null>(null);
    const [inputSymbol, setInputSymbol] = useState(Object.values(props.fromTokens)[0].symbol);
    const [inputAddress, setInputAddress] = useState(Object.keys(props.fromTokens)[0]);
    const [inputTokenBalance, setInputTokenBalance] = useState<number>(0);
    const [outputTokenBalance, setOutputTokenBalance] = useState<number>(0);
    const [exchangeRate, setExchangeRate] = useState<number|null>(null);
    const [inputAmount, setInputAmount] = useState<number>(0);
    const [outputAmount, setOutputAmount] = useState<number>(0);
    const [approvedAmount, setApprovedAmount] = useState<number>(0);
    // const [dollarPrice, setDollarPrice] = useState<number>(0);

    const swapRpcContract = getRpcContract(
        props.spenderAddress,
        props.spenderAddress === '0x732BDEf513Ba8dbf68b878450D46380ee0Ed271D' ? require("../Components/Web3Context/abi/bitshareswap.abi.json") : require("../Components/Web3Context/abi/bitbombswap.abi.json"),
        false
    );

    async function updateInputAmount(elem: HTMLInputElement) {
        console.log(`Element: ${elem.id}   Value: ${elem.value}`);
        setInputAmount(elem.value === "" ? 0 : elem.valueAsNumber);
        if (exchangeRate == null) {
            return;
        }
        setOutputAmount(elem.value === "" ? 0 : elem.valueAsNumber * exchangeRate);
    }

    async function updateOutputAmount(elem: HTMLInputElement) {
        console.log(`Element: ${elem.id}   Value: ${elem.value}`);
        setOutputAmount(elem.value === "" ? 0 : elem.valueAsNumber);
        if (exchangeRate == null) {
            return;
        }
        setInputAmount(elem.value === "" ? 0 : elem.valueAsNumber / exchangeRate);
    }

    useEffect(() => {
        if (web3Connected) {
            fetchApproval();
            fetchWalletBalance();
        }
        fetchExchangeRate();
        fetchDollarPrice();
        fetchRemaining();

        const interval = setInterval(async () => {
            if (web3Connected) {
                fetchApproval();
                fetchWalletBalance();
            }
            fetchExchangeRate();
            fetchDollarPrice();
            fetchRemaining();
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [web3Connected, walletAddress, props.symbolAddress, inputAddress]);

    async function fetchApproval() {
        var allow = await fetchApprovalAmountForSpender(inputAddress, props.spenderAddress, 18);
        setApprovedAmount(allow);
    }

    async function fetchWalletBalance() {
        const inputTokenBalance = await fetchAssetBalance(inputAddress);
        setInputTokenBalance(inputTokenBalance);

        const outputTokenBalance = await fetchAssetBalance(props.symbolAddress);
        setOutputTokenBalance(outputTokenBalance);
    }

    async function fetchExchangeRate() {
        if (props.symbolAddress === '0x39739A969ff667C91a81252b3DDDFAe31aaE4Aeb') {
            setExchangeRate(1);
        }
        if (props.symbolAddress === '0x123c62f04c9D70bD6F04E6282434c728F04eBD50') {
            setExchangeRate(0.3);
        }
        if (props.symbolAddress === '0x3CdC14690B4930f8b61A2195062f5AB8A6beC48d') {
            setExchangeRate(0.2250);
        }
        if (props.symbolAddress === '0x9F45C43c5Ed3c1a7FD7fb9f3b611860AE4dF2453') {
            setExchangeRate(4.5);
        }
        if (props.symbolAddress === '0x116B19FBAFCc947E7Ffcc3A42ff7185589143a56') {
            setExchangeRate(0.01);
        }
    }

    async function fetchDollarPrice() {
        // if (exchangeRate == null) {
        //     return;
        // }
        // const price = 1 / exchangeRate * await getTokenPriceFromPancakeswapInDollars(props.symbolAddress);
        // setDollarPrice(price);
    }

    async function fetchRemaining() {
        let symbolShortcut = '';
        if (props.symbolAddress === '0x39739A969ff667C91a81252b3DDDFAe31aaE4Aeb') {
            symbolShortcut = 'Bitbtc';
        }
        if (props.symbolAddress === '0x123c62f04c9D70bD6F04E6282434c728F04eBD50') {
            symbolShortcut = 'Bitdot';
        }
        if (props.symbolAddress === '0x3CdC14690B4930f8b61A2195062f5AB8A6beC48d') {
            symbolShortcut = 'Bitatom';
        }
        if (props.symbolAddress === '0x9F45C43c5Ed3c1a7FD7fb9f3b611860AE4dF2453') {
            symbolShortcut = 'Bitada';
        }
        if (props.symbolAddress === '0x116B19FBAFCc947E7Ffcc3A42ff7185589143a56') {
            symbolShortcut = 'Bitshare';
        }

        // @ts-ignore
        let fLLower = s => s.replace(/./, c => c.toLowerCase());
        const ptotal = await swapRpcContract.methods[fLLower(symbolShortcut) + 'Tokens']().call() / (10 ** 18);
        setTotal(ptotal);

        const pavailable = await swapRpcContract.methods['available' + symbolShortcut]().call() / (10 ** 18);
        setAvailable(pavailable);

        const posold = await swapRpcContract.methods['sold' + symbolShortcut]().call() / (10 ** 18);
        setSold(posold);
    }

    const now = moment();
    const presaleStartDate = moment.unix(Number(props.startTime));

    const fromTokenSelectRef= useRef<any>();

    return (
        <div
            className={
                "py-6 px-8 overflow-hidden bg-white card relative shadow rounded-xl text-left text-gray-800 dark:text-light mx-auto w-full" +
                (props.featured ? " p-0.5 bg-gradient-to-tr from-yellow-500 via-red-500 to-indigo-700 " : " bg-white dark:bg-dark2-500") +
                (props.className ? ` ${props.className}` : "")
            }
        >
            <div className="flex flex-col items-center">
                <div
                    className="font-extrabold text-2xl items-center gap-2 font-urbanist mb-4 whitespace-nowrap dark:text-light">
                    {props.title}
                </div>
                <div
                    className="font-extrabold text-2xl items-center gap-2 font-urbanist mb-1 whitespace-nowrap dark:text-light">
                    1 {Object.values(props.fromTokens).map((t: any) => t.symbol).join('/')} = {exchangeRate} {props.symbol}
                </div>
                <div
                    className="text-md items-center gap-2 font-urbanist mb-4 whitespace-nowrap dark:text-light">
                    Total: { total ? round(total, 2) : '' } | Sold: { sold ? round(sold, 2) : '' } | Remaining: {available ? round(available, 2) : '' }
                </div>
                <div className="text-black-500 text-left text-2xl sm:text-3xl md:text-4xl font-semibold mb-0 text-center"><span className="text-primary-500">//</span></div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                    {web3Connected && (
                        <>
                            <p style={{ 'lineHeight': '5px' }}>&nbsp;</p>
                            <div className="flex justify-center">
                                <div className="mb-6 xl:w-96">
                                    <label htmlFor="exampleText0" className="form-label inline-block mb-2 text-center text-md w-full dark:text-white">Select token to convert into {props.symbol}:</label>
                                    <select
                                        ref={fromTokenSelectRef}
                                        onChange={(e) => {
                                            setInputAmount(0);
                                            setInputAddress(e.target.value)
                                            setInputSymbol(fromTokenSelectRef.current.options[fromTokenSelectRef.current.selectedIndex].text)
                                        }}
                                        defaultValue={"000000000000"}
                                        className="form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700
                                                  bg-white bg-clip-padding bg-no-repeat
                                                  dark:bg-dark2-500
                                                  dark:text-white
                                                  border border-solid border-gray-300
                                                  rounded
                                                  transition
                                                  ease-in-out
                                                  m-0
                                                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example">
                                        { Object.entries(props.fromTokens).map(([key]) => (
                                            <option key={key} value={key}>{props.fromTokens[key].symbol}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="flex justify-evenly w-full gap-4 flex-wrap relative">
                                <div
                                    className="text-xs text-slate-400 self-center cursor-pointer mr-4 absolute right-20 z-50"
                                    onClick={() => {
                                        const elem = document.getElementById(props.amountElementId) as HTMLInputElement;
                                        // If fromToken is BNB then subtract a few digits to make sure there is enough to cover the gas
                                        elem.value = String(inputTokenBalance);
                                        updateInputAmount(elem);
                                    }}
                                >
                                    MAX
                                </div>
                                <div
                                    className="text-xs text-slate-400 self-center cursor-pointer mr-2 absolute right-0 -top-5 z-50"
                                    onClick={() => {
                                        const elem = document.getElementById(props.amountElementId) as HTMLInputElement;
                                        // If fromToken is BNB then subtract a few digits to make sure there is enough to cover the gas
                                        elem.value = String(inputTokenBalance);
                                        updateInputAmount(elem);
                                    }}
                                >
                                    Balance: {roundAndFormatNumber(inputTokenBalance, 8)}
                                </div>
                                <MiniEditablePrice
                                    icon={"https://swap.czpegs.com/images/tokens/" + inputAddress + ".png"}
                                    type="number"
                                    iconAlt={inputSymbol}
                                    title={`Enter Amount`}
                                    value={inputAmount}
                                    elementId={props.amountElementId}
                                    onChange={(val) => updateInputAmount(val.currentTarget)}
                                    label={inputSymbol}
                                />
                            </div>

                            <div className="text-center text-md mt-5 mb-8">You will receive:</div>

                            <div className="flex justify-evenly w-full gap-4 flex-wrap relative mt-7">
                                <div
                                    className="text-xs text-slate-400 self-center mr-2 absolute right-0 -bottom-5 z-50"
                                >
                                    Balance: {roundAndFormatNumber(outputTokenBalance, 8)}
                                </div>
                                <MiniEditablePrice
                                    icon={"https://swap.czpegs.com/images/tokens/" + props.symbolAddress + ".png"}
                                    type="number"
                                    iconAlt={props.symbol}
                                    title={`Output Amount`}
                                    value={outputAmount}
                                    elementId={props.amountElementId + '_output'}
                                    onChange={(val) => updateOutputAmount(val.currentTarget)}
                                    label={props.symbol}
                                />
                            </div>
                        </>
                    )}
                </div>
                {!web3Connected ? (
                    <ConnectButton
                        className=""
                        connectWeb3={connectWeb3}
                        disconnectWeb3={disconnectWeb3}
                        web3Connected={web3Connected}
                        walletAddress={walletAddress}
                    />
                ) : (
                    <>
                        {/*footer*/}
                        <div className="flex items-center justify-end p-6 pt-4 border-solid border-slate-200 rounded-b">
                            {approvedAmount == 0 ? (
                                <button
                                    className="flex-1 rounded-full bg-gradient-to-b from-[#ffd702] to-[#ffd702] text-black uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#fee22e] focus:outline-none focus:ring focus:ring-[#fee22e] focus:ring-opacity-75 whitespace-nowrap"
                                    onClick={async () => {
                                        await approveAmountForSpender(inputAddress, props.spenderAddress, 18);
                                        await fetchApproval();
                                    }}
                                >
                                    Approve {inputSymbol}
                                </button>
                            ) : (
                                <>
                                {presaleStartDate > now || !available || available <= 0 ? (
                                    <span
                                        className="flex-1 rounded-full bg-gray text-black uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] whitespace-nowrap"
                                    >
                                        {props.title}
                                    </span>
                                ) : (
                                <button
                                    className="flex-1 rounded-full bg-gradient-to-b from-[#ffd702] to-[#ffd702] text-black uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#fee22e] focus:outline-none focus:ring focus:ring-[#fee22e] focus:ring-opacity-75 whitespace-nowrap"
                                    onClick={() => {
                                        // if (inputAmount < props.minimum) {
                                        //     alert('Minimum purchase amount is ' + props.minimum + ' ' + props.symbol);
                                        //     return;
                                        // }
                                        if (inputAddress == '0x522348779DCb2911539e76A1042aA922F9C47Ee3') {
                                            buyWithBomb(props.symbolAddress, inputAmount);
                                            return;
                                        }

                                        if (inputAddress == '0xDA1d9C79240003195d0a67f202efcCCC3F78b994') {
                                            buyWithBbond(props.symbolAddress, inputAmount);
                                            return;
                                        }

                                        if (inputAddress == '0x531780FAcE85306877D7e1F05d713D1B50a37F7A') {
                                            buyWithBshare(props.symbolAddress, inputAmount);
                                            return;
                                        }

                                        alert('Unsupported token');
                                    }}
                                >
                                    {props.title}
                                </button>
                                )}
                                </>
                            )}
                        </div>
                    </>
                )}
                {presaleStartDate > now && (
                    <div className="mt-2">
                        Swap starts in <Countdown date={presaleStartDate.toDate()} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default PresaleBuyCard;
